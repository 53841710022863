import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Check from './Check';
import Contact from './Contact';
import Configdata from './Defaultdata';
import './mainapp.css';
export default class Abroadjob extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        expoption: null,
        resm: null,
        url: "",
        walk: "",
        tpb: null,
        opening: [{}]
    };
 
    topban = [{}];
    expops = [{}];
    middleban = [{}];
    bottonban = [{}];
    walkins = [{}];
    openings = [{}];
   
    
   

   
    handleChangecontact = () => {
        const jid = localStorage.getItem('hotjobid');
        if (jid != 'null') {
            const element = <Contact />
            ReactDOM.render(element, document.getElementById('container'));
        }
       
    };
    setserverurl() {
        debugger;
        const appurl = Configdata.srvurl;
       
        this.setState({ url: appurl });
    }
    componentDidMount() {
        debugger;
      
        this.setserverurl();
       
        this.BindAlldivs();

        this.Bindbanerbdiv();
        
    }
    
    Bindbanerbdiv() {

        debugger;
        var divdata = '';


        fetch(Configdata.srvurl + "/getonlinetdownloadbaner/" + "10")
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    const ub = data[0];
                    const sb = ub.subj;
                    const lnk = ub.pagelink;

                    const p = '<div className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</div>'

                    divdata = divdata + p;

                    this.setState({ walk: divdata });
                    this.tbarfech(divdata);
                }

            }
            );



    }
    tbarfech(d: any) {
        if (d == null || d == undefined)
            d = 'Gostepsup.com'
        const divbbaner = document.getElementById('topadd') as HTMLElement;
        const divbbanerbtn = document.getElementById('homebuttombnr') as HTMLElement;

        divbbaner.innerHTML = d;
        divbbanerbtn.innerHTML = d;
    }
    BindAlldivs() {
        debugger;
        var divdata = '';
      
      
        fetch(Configdata.srvurl + "/getonlinetdownloadabroadjob")
            .then((res) => res.json())
            .then((data) => {
              
                debugger;
                if (data.length > 0) {
                 
                   
                 
                    data.forEach((u: any) => {
                        debugger;
                      
                        const vsb = u.subj;
                        const vlnk = u.pagelink;
                        const vid = u.id;
                        const vobj = { sb: vsb, lnk: vlnk, id: vid };
                     
                      
                        
                         if (u.typeid == 5 && this.openings.length > 0) {

                            this.openings.push(vobj);
                            

                        }
                     
                    })
                   
                    this.Bindhomenonitdiv(this.openings);
                    this.setState({ opening: this.openings });

                }
               


            }
            );

      

    }
   
   
   
    Bindhomenonitdiv(data: any) {
        debugger;
        var divdata = '';
        data.forEach((u: any) => {
            if (u.sb != undefined) {
                const sb = u.sb;
                const lnk = u.lnk;

                const p = '<p>' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;
            }



        })
        this.setState({ opening: divdata });
        this.openingsfechadd(divdata);

    }
   
   
   
    openingsfechadd(d: any) {

        const divjobwalkinss = document.getElementById('jobopenings') as HTMLElement;
        divjobwalkinss.innerHTML = d;
    }
 render() {
for (let i = 1; i <= 40; i++) {
  var opt = {value: i, label: i};
this.expops.push(opt);
}
    
     return (

<form>
          <div className="maindiv">
                <div className="top-dibv-add">
              
                     <div id="topadd" onClick={this.handleChangecontact} className="top-add">
                         &nbsp;
                     </div>
                  
                 
                 </div>
                <div style={{ height: '30em', overflow: 'scroll' }} className="home-table">
                    
                  
                     <table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">
                        
                         <tr>
                            
                           
                             <td style={{ width: '100%', border: 'none' }}>
                                 <div className="middlediv">
                                     <div className="containerlinkdiv" id="jobopenings" >
                                     </div>
                                 </div>
                             </td>
                         </tr>

                         </table>
                     <div className="top-dibv-add">

                             <div id="homebuttombnr" onClick={this.handleChangecontact} className="top-add">
                             &nbsp;
                             </div>
                         
                        </div>
              
                  
              </div>
            
    

        </div>



   </form>

      );
}
}
//export default Home;

import React, { Component } from 'react';
import { useState, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import './mainapp.css';
import Select from 'react-select';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';

import * as ReactDOM from 'react-dom';

function App() {
	useEffect(() => {
		const link = document.querySelector('link[rel="icon"]');

		if (link) {
			link.setAttribute('href', 'favicon-devp.ico');
		}
	}, []);

  return (
  <div>
	 
	<Header />
		  <div id="container" className="containtdiv">
			  <Home />
	
	</div>
	<Footer />

</div>

      );
}

export default App;

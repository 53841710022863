import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Check from './Check';
import Contact from './Contact';
import Configdata from './Defaultdata';
import './mainapp.css';

export default class Home extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        expoption: null,
        catoption: null,
        bindopt: null,
        isVisible: true,
        resm: null,
        url: "",
        walk: "",
        citywalk:"",
        tpb: null,
        opening: [{}]
    };
 options = [
  { value: '1', label: 'IT' },
  { value: '2', label: 'Non-IT' },
  { value: '3', label: 'Others' }
 ];
    topban = [{}];
    expops = [{}];
    middleban = [{}];
    bottonban = [{}];
    walkins = [{}];
    openings = [{}];
    citywalkins = [{}];
    cityopenings = [{}];
    cities = [
        { value: 0, label: 'Others' },
        { value: 1, label: 'New Dehli' },
        { value: 2, label: 'Gurgaon' },
        { value: 3, label: 'Novaluea' },
        { value: 4, label: 'Mumbai' },

        { value: 5, label: 'Kolkata' },
        { value: 6, label: 'Howrah' },
        { value: 7, label: 'Chennai' },
        { value: 8, label: 'Hyderabad' },
        { value: 9, label: 'Bengaluru' },
        { value: 10, label: 'Ahmedabad' },
        { value: 11, label: 'Surat' },
        { value: 12, label: 'Amritsar' },
        { value: 13, label: 'Bhopal' },
        { value: 14, label: 'Bhubaneswar' },
        { value: 15, label: 'Cuttack' },
        { value: 16, label: 'Chandigarh' },
        { value: 17, label: 'Farvalueabad' },
        { value: 18, label: 'Ghaziabad' },
        { value: 19, label: 'Jamshedpur' },
        { value: 20, label: 'Jaipur' },
        { value: 21, label: 'Kochi' },
        { value: 22, label: 'Lucknow' },
        { value: 23, label: 'Nagpur' },
        { value: 24, label: 'Patna' },
        { value: 25, label: 'Raipur' },
        { value: 26, label: 'Visakhapatnam' },
        { value: 27, label: 'Agra' },
        { value: 28, label: 'Ajmer' },
        { value: 29, label: 'Kanpur' },
        { value: 30, label: 'Mysore' },
        { value: 31, label: 'Srinagar' },
        { value: 32, label: 'Pune' },
    ];
    typehandleChange(event: any) {
        const tv = event.value;
       
        this.setState({ typeoption: tv });
    }
    exphandleChange(event: any) {
        const ev = event.value;
       
        this.setState({ expoption: ev });
    }
   
    cityhandleChange(event: any) {
        const tv = event.value;
      
        localStorage.setItem("localcityid", tv);
        event.defaultPrevented = true;
        // event.preventDefault;
    }
    sendtoserver(vnm: any, vph: any, vtyp: any, vexp: any, vremarks: any) {
        const localurl = this.state.url;
       
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: vnm, phone: vph, type: vtyp, exp: vexp, rmk: vremarks })
        };
        try {
            const promiseAwait = fetch(localurl + '/receive', requestOptions);
            alert('success');

        }
        catch (e) {
            debugger;


        }
    }
    Homehitcount() {
              
        try {
          
            const promiseAwait = fetch(Configdata.srvurl + '/Hitcountinput');
          }
        catch (e) {
            debugger;


        }
    }
    handleChangecontact = () => {
        const jid = localStorage.getItem('hotjobid');
        if (jid != 'null') {
            const element = <Contact />
            ReactDOM.render(element, document.getElementById('container'));
        }
       
    };
    setserverurl() {
        debugger;
        const appurl = Configdata.srvurl;
       
        this.setState({ url: appurl });
    }
    componentDidMount() {
        debugger;
       
       
           
        this.Homehitcount();
        this.setserverurl();
       
        this.BindAlldivs();
        var typeid = localStorage.getItem("localtypeid");
        if (typeid == "7")
            this.Bindcitywalkinsdata();
        else if (typeid == "8")
            this.Bindcityopeningsdata();
    }
    disablealldiv(){
    const scriptdiv = document.getElementById('homejobopenings') as HTMLElement;
    scriptdiv.style.display = 'none';
        const statusdiv = document.getElementById('homejobwalkins') as HTMLElement;
    statusdiv.style.display = 'none';
    }
    enablealldiv() {
        const scriptdiv = document.getElementById('homejobopenings') as HTMLElement;
        scriptdiv.style.display = 'block';
        const statusdiv = document.getElementById('homejobwalkins') as HTMLElement;
        statusdiv.style.display = 'block';
    }
   
   
    hotjoblefttopbarfech(d: any) {

        const divjobhotleftbbar = document.getElementById('homemiddlebnr') as HTMLElement;
     
        if (d == null || d == undefined || d == '') {
            d = 'Gostepsup.com';
        }
            divjobhotleftbbar.innerHTML = d;
        
        
       
    }
    hotjobleftbuttombarfech(d: any) {

        const divjobhotleftbbar = document.getElementById('homebuttombnr') as HTMLElement;
        if (d == null || d == undefined || d == '') {
            d = 'Gostepsup.com';
        }
        divjobhotleftbbar.innerHTML = d;
    }
    BindAlldivs() {
        debugger;
       
        var divdata = '';
        var url = "/gethotjobs";
        fetch(Configdata.srvurl + url)
            .then((res) => res.json())
            .then((data) => {
               
                if (data.length > 0) {
                    data.forEach((u: any) => {

                        const vsb = u.subj;
                        const vlnk = u.pagelink;
                        const vid = u.id;
                        const vobj = { sb: vsb, lnk: vlnk, id: vid };

                        if (u.setid == 1 && this.topban.length == 1) {

                            this.topban.push(vobj);
                            const p = '<div>' + '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + vsb + '</a>' + '</div>'


                            this.settingclients(vlnk, p, vsb, vid);


                        }
                        else if (u.setid == 5 && this.middleban.length == 1) {

                            this.middleban.push(vobj);
                            const p = '<p>' + '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + vsb + '</a>' + '</p>'

                            this.hotjoblefttopbarfech(p);

                        }

                        else if (u.setid == 6 && this.bottonban.length == 1) {

                            this.bottonban.push(vobj);
                            const p = '<div>' + '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + vsb + '</a>' + '</div>'

                            divdata = divdata + p;


                            this.hotjobleftbuttombarfech(divdata);

                        }
                        else if (u.setid == 7 && this.walkins.length > 0) {

                            this.walkins.push(vobj);


                        }
                        else if (u.setid == 8 && this.openings.length > 0) {

                            this.openings.push(vobj);


                        }
                      

                    })
                    
                    this.Bindhomewalkindiv(this.walkins);
                    this.Bindhomeopeningsdiv(this.openings);
                    this.setState({ opening: this.openings });

                    
                }
               


            }
            );

      

    }
    Bindcityopeningsdata() {
        debugger;
        var cid = localStorage.getItem("localcityid");
        var cityid = cid;
        var divdata = '';
        var url = "/getcityhotjobs/" + cityid + "/" + "8";
        if (this.state.catoption != null && this.state.catoption != 'undefined')
            cityid = this.state.catoption;
            
        if (cityid != '0' && cityid != null && cityid != 'undefined') {
          
            fetch(Configdata.srvurl + url)
                .then((res) => res.json())
                .then((data) => {

                    if (data.length > 0) {
                        data.forEach((u: any) => {

                            const vsb = u.subj;
                            const vlnk = u.pagelink;
                            const vid = u.id;
                            const vobj = { sb: vsb, lnk: vlnk, id: vid };

                           
                                if (this.citywalkins.length > 0 && u.cityid == cityid && u.setid == 8) {

                                    this.citywalkins.push(vobj);


                                }
                            

                        })

                        this.Bindhomecitywalkinopeningdiv(this.citywalkins);
                       

                    }



                }
                );
        }
       
    

    }

    Bindcitywalkinsdata() {
        debugger;
        var cid = localStorage.getItem("localcityid");
        var cityid = cid;
        var divdata = '';
        var url = "/getcityhotjobs/" + cityid + "/" + "7";
        if (this.state.catoption != null && this.state.catoption != 'undefined')
            cityid = this.state.catoption;

        if (cityid != '0' && cityid != null && cityid != 'undefined') {
           
            fetch(Configdata.srvurl + url)
                .then((res) => res.json())
                .then((data) => {

                    if (data.length > 0) {
                        data.forEach((u: any) => {

                            const vsb = u.subj;
                            const vlnk = u.pagelink;
                            const vid = u.id;
                            const vobj = { sb: vsb, lnk: vlnk, id: vid };


                            if (this.citywalkins.length > 0 && u.cityid == cityid && u.setid == 7) {

                                this.citywalkins.push(vobj);


                            }


                        })

                        this.Bindhomecitywalkinopeningdiv(this.citywalkins);


                    }



                }
                );
        }



    }
    settingclients(url: any, subl: any,sub: any, id: any) {
        const txt = '<a href="#">GOSPETSUP OPENINGS</a>';
        const divtopadd = document.getElementById('topadd') as HTMLElement;
        divtopadd.innerHTML = "  ";

        if (url == null || url == undefined || url == '') {
           
            localStorage.setItem("hotjobid", id);
           if(sub != null && sub != undefined)
            divtopadd.innerHTML =  sub ;
        }
        else {
           
            localStorage.setItem("hotjobid", 'null');
            if(subl != null && subl != undefined )
            divtopadd.innerHTML = subl;
        }
            

    }
   
    Bindhomewalkindiv(data: any) {
        debugger;
        var divdata = '';
        data.forEach((u: any) => {
            if (u.sb != undefined) {
                const sb = u.sb;
                const lnk = u.lnk;

                const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;
            }
            


        })
        this.setState({ walk: divdata });
        this.walkinfechadd(divdata);

    }
    Bindhomecitywalkinopeningdiv(data: any) {
        debugger;
        var divdata = '';
        data.forEach((u: any) => {
            if (u.sb != undefined) {
                const sb = u.sb;
                const lnk = u.lnk;

                const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;
            }



        })
        this.setState({ citywalk: divdata });
        this.citywalkinopeningfechadd(divdata);

    }
    Bindwalkin() {
        localStorage.setItem("localtypeid","7");
        this.Bindcitywalkinsdata();
        this.setState({ bindopt: '1' });
    }
    Bindopening() {
        localStorage.setItem("localtypeid", "8");
        this.Bindcityopeningsdata();
        this.setState({ bindopt: '0' });
    }
    Bindhomeopeningsdiv(data: any) {
        debugger;
        var divdata = '';
        data.forEach((u: any) => {
            if (u.sb != undefined) {
                const sb = u.sb;
                const lnk = u.lnk;

                const p = '<p>' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;
            }



        })
        this.setState({ opening: divdata });
        this.openingsfechadd(divdata);

    }
   
   
    Binhotjobdiv() {
        debugger;

        const lnames = [
            { value: '0', label: 'Other' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getwalkins")
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { value: '', label: '' };



                    us.label = u.sname.toString();

                    us.value = u.id.toString();
                    lnames.push(us);


                })
                this.setState({ Snames: lnames });

            }
            );
    }
   
    insertresume() {
       
      
       
    }
    handleClick() {
    
                  
    } 
    walkinfechadd(d: any) {
     
        const divjobwalkinss = document.getElementById('homejobwalkins') as HTMLElement;
        const divjobwalkinss1 = document.getElementById('homejobsopen') as HTMLElement;
        if (d == null || d == undefined || d == '') {
            d = 'Gostepsup.com';
        }
        divjobwalkinss.innerHTML = d;
       /* divjobwalkinss1.innerHTML = d;*/
    }
    citywalkinopeningfechadd(d: any) {
       
        const divjobwalkinss = document.getElementById('Citywalkinjobsshow') as HTMLElement;
        if (d == null || d == undefined || d == '') {
            /* d = 'No data found';*/
            d = '&nbsp;';
        }
        divjobwalkinss.innerHTML = d;
    }
    openingsfechadd(d: any) {

        const divjobwalkinss = document.getElementById('homejobsopen') as HTMLElement;
        if (d == null || d == undefined || d == '') {
            d = 'Gostepsup.com';
        }
      
        divjobwalkinss.innerHTML = d;
    }
 render() {
for (let i = 1; i <= 40; i++) {
  var opt = {value: i, label: i};
this.expops.push(opt);
}
   
     return (

<form>
          <div className="maindiv">
                 <div className="top-dibv-add" style={{ paddingTop: '0px',paddingBottom:'2px'} }> 
              
                     <div id="topadd" onClick={this.handleChangecontact} className="top-add-home">
                        
                     </div>
                 
                 
                 </div>
                 <div className="top-dibv-scr">
                     <div className="searchdiv">

                         <div className="job-search-city">
                             <Select onChange={e => this.cityhandleChange(e)} id="ctype" options={this.cities} />

                         </div>




                     </div>
                 </div>
                 {/* <div className="top-dibv-scr" style={{ marginTop: '18px' }}>*/}
                 <div className="top-dibv-scr">
                     <div className="searchdiv">


                         <div className="job-search-city">
                             <span className="job-search-walk-btn" style={{ width: '50%' }} >
                                 <button style={{ height: '30px', width: '100%', float: 'left' }} onClick={this.Bindwalkin.bind(this)}>Walkin</button>
                             </span>
                             <span className="job-search-opening-btn" style={{ width: '50%' }}>
                                 <button style={{ height: '30px', width: '100%', float: 'right' }} onClick={this.Bindopening.bind(this)}>Opening</button>
                             </span>
                         </div>



                     </div>
                 </div>
                 <div style={{ height: '30em', overflow: 'scroll', width: '99%' }} className="home-table">
                     <table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">

                         <tr>


                             <td style={{ width: '100%', borderStyle: 'outset' }}>
                                 <div className="middlediv">
                                     <div className="containerlinkdiv-city" style={{ width: '98%', height: 'auto',minHeight:'0px' }} id="Citywalkinjobsshow" >
                                     </div>
                                 </div>
                             </td>
                         </tr>



                     </table>
                     <table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">

                         <tr>
                            

                             <td style={{ width: '100%', borderStyle: 'outset' }}>
                                 <div className="middlediv">
                                     <div className="containerlinkdiv"  id="homejobwalkins" >
                                     </div>
                                 </div>
                             </td>
                         </tr>

                         

                     </table>
                     <div className="top-dibv-add">

                         <div id="homemiddlebnr" onClick={this.handleChangecontact} className="top-add-home">
                         &nbsp;
                     </div>
                     </div>
                     <table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">

                         <tr>


                             <td style={{ width: '100%', borderStyle: 'outset' }}>
                                 <div className="middlediv">
                                     <div className="containerlinkdiv" id="homejobsopen" >
                                     </div>
                                 </div>
                             </td>
                         </tr>



                     </table>
                     {/*<table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">*/}

                     {/*    <tr>*/}


                     {/*        <td style={{ width: '100%', borderStyle: 'outset' }}>*/}
                     {/*            <div className="middlediv">*/}
                     {/*                <div className="containerlinkdiv" id="homejobopenings" >*/}
                     {/*                </div>*/}
                     {/*            </div>*/}
                     {/*        </td>*/}
                     {/*    </tr>*/}

                     {/*</table>*/}
                     {/*<div className="top-dibv-add">*/}
                        
                     {/*</div>*/}
                    
                     <div className="top-dibv-add" style={{ marginBottom:"1.5%" }}>

                             <div id="homebuttombnr" onClick={this.handleChangecontact} className="top-add-home">
                            
                             </div>
                         
                        </div>
              
                  
              </div>
            
    

        </div>

             <div style={{ display: "none" }} id="jobopenings" > </div>

   </form>

      );
}
}
//export default Home;

import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import Abtdiv from './Abtdiv';
import Home from './Home';
import Contact from './Contact';
import Check from './Check';
import Login from './Login';
import Undercon from './Undercon';
import Jobhome from './Jobhome';
import Onlinetest from './Onlinetest';
import Resume from './Resume';
import Configdata from './Defaultdata';
import './menu.css';
import Aprisal from './Aprisal';
import Resignation from './Resignation';
import Software from './Software';
import Abroadjob from './Abroadjob';
import Abroadstudy from './Abroadstudy';

class Header extends Component {
state = {
    abt: 1,cnt:2
  }; 
 handleAgeChange = () => {
	
	 const element = <Jobhome />
	 ReactDOM.render(element, document.getElementById('container'));
 };
	handleChangeonlinetest = () => {
		const element = <Onlinetest />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangecontact = () => {
		const element = <Contact />
		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangehome = () => {
		const element = <Home />;

		ReactDOM.render(element, document.getElementById('container'));

		
	};
	handleChangelogin = () => {
		const element = <Login />
		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangeresume = () => {
		const element = <Resume />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangeaprisal = () => {
		const element = <Aprisal />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangeresignation = () => {
		const element = <Resignation />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangesoftware = () => {
		const element = <Software />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangeabroadjob = () => {
		const element = <Abroadjob />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangeabroadstudy = () => {
		const element = <Abroadstudy />

		ReactDOM.render(element, document.getElementById('container'));
	};
	render() {

		
  return (
  <div>
		  <div className="topdiv" style={{ width: '96%' }}>

	 {/*    <div className="userprofile">*/}
		{/*<a href="#">*/}
		{/*<span className="floatright">Profile List</span>*/}
		{/*</a>*/}
	 {/*  </div>*/}
	 {/* <div className="userprofile">*/}
		{/*<a href="#">*/}
		{/*<span className="floatright">Profile</span>*/}
		{/*</a>*/}
	 {/* </div>*/}

	 <div className="userprofile">
		<a  href="#">
		<span className="floatright">Sign Up</span>
		</a>
	 </div>

	<div className="userprofile">
				  <a onClick={this.handleChangelogin} href="#">
				 
		<span className="floatright"> SignIn </span>
		</a>
	</div>
	
  </div>
		  <div className="topdiv" id="usernamedispaly" style={{display: 'none' }}>
			  <div className="userprofile">
				  <a href="#">
					  <span id="welcome" className="floatright">  </span>
				  </a>
			  </div>
		  </div>
	
		  <div className="submenudiv">
		  <nav>
				  <ul className='menu'>
					  <li><a className='home' onClick={this.handleChangehome} href='#'>Home</a></li>
					

					  <li><a className='home' href='#' title='Menu'>Download</a>
						  <ul className='menus'>
							  <li className='has-submenu'><a className='prett' href='#' title='Dropdown 1'>Templete</a>
								  <ul className='submenu'>
									  <li><a onClick={this.handleChangeresume} href="#" title="Sub Menu">Resume</a></li>
									  <li><a onClick={this.handleChangeaprisal} href="#" title="Sub Menu">Aprisal</a></li>
									  <li><a onClick={this.handleChangeresignation} href="#" title="Sub Menu">Resignation</a></li>
							  </ul>
						  </li>
							  <li><a onClick={this.handleChangesoftware} href='#' title='Dropdown 2'>Softwares</a></li>
						  
					  </ul>
					  </li>


					  <li><a className='home' onClick={this.handleChangeonlinetest} href='#'>Test</a></li>

					  {/* <li><a className='home' onClick={this.handleAgeChange} href='#'>Adv&#128269;</a></li>*/}
					  <li><a className='home' href='#' title='Menu'>Scopes</a>
						  <ul className='menus'>
							  <li className='has-submenu'><a className='prett' href='#' title='Abroad'>Abroad</a>
								  <ul className='submenu'>
									  <li><a onClick={this.handleChangeabroadjob} href="#" title="Sub Menu">Job</a></li>
									  <li><a onClick={this.handleChangeabroadstudy} href="#" title="Sub Menu">Study</a></li>
									  
								  </ul>
							  </li>
							  <li><a  href='#' title='Dropdown 2'>Work from Home</a></li>

						  </ul>

					  </li>

					  <li><a className='home' onClick={this.handleChangecontact} href='#'>Contact</a></li>


			  </ul>
			  </nav>
		  </div>
</div>

      );
}
}
export default Header;
